import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
// @mui
import { useTheme } from '@mui/material/styles';
import { Box } from '@mui/material';

// ----------------------------------------------------------------------

Logo.propTypes = {
  disabledLink: PropTypes.bool,
  sx: PropTypes.object,
};

export default function Logo({ disabledLink = false, sx }) {
  const theme = useTheme();
  const PRIMARY_LIGHT = theme.palette.primary.light;
  const PRIMARY_MAIN = theme.palette.primary.main;
  const PRIMARY_DARK = theme.palette.primary.dark;

  const logo = (
    <Box sx={{ width: 40, height: 40, ...sx }}>
   <svg xmlns="http://www.w3.org/2000/svg"   width="100%" height="100%" viewBox="0 0 3264.000000 3264.000000">
        <defs>
          <linearGradient id="BG1" x1="100%" x2="50%" y1="9.946%" y2="50%">
            <stop offset="0%" stopColor={PRIMARY_DARK} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG2" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG3" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
        </defs>
        <g transform="translate(0.000000,3264.000000) scale(0.100000,-0.100000)"
fill={PRIMARY_MAIN}  stroke="none" strokeWidth="1"  fillRule="evenodd">
<path d="M15560 29530 c-74 -4 -153 -10 -175 -14 -51 -8 -323 -25 -525 -31
-162 -6 -541 -38 -647 -55 -34 -6 -96 -22 -137 -36 -46 -15 -116 -29 -174 -34
-215 -18 -393 -48 -557 -95 -129 -37 -164 -47 -275 -76 -63 -17 -149 -34 -190
-39 -41 -5 -124 -23 -185 -40 -60 -17 -137 -38 -170 -46 -32 -9 -72 -22 -87
-30 -15 -8 -40 -20 -56 -28 -15 -8 -56 -19 -92 -26 -109 -19 -288 -72 -327
-96 -12 -8 -48 -21 -80 -30 -159 -45 -193 -55 -253 -77 -36 -13 -75 -29 -87
-35 -12 -7 -30 -12 -41 -12 -28 0 -134 -42 -249 -98 -22 -12 -61 -26 -85 -32
-57 -14 -186 -66 -228 -91 -20 -12 -41 -21 -125 -55 -38 -15 -95 -44 -125 -64
-65 -42 -119 -68 -180 -85 -105 -30 -365 -147 -420 -189 -21 -16 -87 -52 -110
-60 -8 -3 -37 -19 -65 -35 -27 -17 -84 -47 -125 -68 -41 -20 -79 -41 -85 -45
-5 -5 -32 -29 -59 -53 -27 -25 -56 -45 -65 -45 -9 0 -25 -6 -36 -14 -23 -16
-127 -73 -233 -127 -41 -21 -82 -45 -91 -53 -10 -9 -21 -16 -25 -16 -5 0 -21
-11 -38 -25 -16 -14 -35 -25 -42 -25 -7 0 -21 -9 -31 -20 -10 -11 -23 -20 -29
-20 -6 0 -28 -16 -49 -35 -21 -19 -48 -37 -60 -40 -42 -13 -145 -67 -170 -91
-26 -24 -60 -46 -128 -83 -20 -11 -50 -34 -67 -51 -17 -16 -36 -30 -44 -30 -7
0 -25 -11 -40 -25 -15 -14 -30 -25 -34 -25 -3 0 -27 -17 -53 -38 -25 -21 -66
-51 -91 -67 -54 -36 -54 -36 -106 -83 -22 -20 -54 -44 -70 -52 -16 -8 -45 -31
-64 -50 -19 -20 -53 -48 -75 -63 -22 -14 -60 -45 -84 -67 -24 -22 -45 -40 -48
-40 -2 0 -29 -19 -59 -42 -163 -127 -221 -174 -244 -198 -14 -15 -34 -33 -46
-41 -52 -35 -176 -161 -189 -192 -18 -44 -59 -78 -118 -101 -26 -10 -47 -22
-47 -26 0 -5 -35 -36 -77 -68 -109 -83 -290 -265 -329 -331 -10 -17 -29 -35
-43 -41 -41 -17 -278 -253 -343 -341 -27 -37 -106 -124 -175 -195 -35 -35 -63
-68 -63 -74 0 -6 -24 -34 -53 -63 -28 -29 -64 -71 -78 -92 -15 -22 -49 -63
-75 -91 -141 -148 -302 -357 -336 -436 -4 -10 -12 -18 -18 -18 -17 0 -64 -50
-97 -101 -15 -25 -34 -51 -41 -59 -32 -36 -102 -136 -102 -146 0 -5 -21 -35
-46 -65 -58 -68 -76 -92 -89 -111 -5 -9 -25 -37 -42 -62 -18 -26 -33 -50 -33
-54 0 -10 -57 -92 -78 -114 -45 -45 -92 -123 -92 -151 0 -8 -17 -34 -38 -58
-20 -24 -43 -55 -51 -69 -8 -14 -30 -52 -49 -85 -18 -33 -41 -68 -49 -78 -23
-24 -51 -75 -150 -270 -34 -65 -74 -135 -91 -155 -35 -45 -66 -104 -88 -169
-9 -26 -29 -66 -45 -88 -17 -22 -44 -62 -61 -90 -17 -27 -44 -69 -60 -93 -16
-24 -40 -71 -54 -105 -14 -34 -45 -102 -69 -152 -57 -119 -68 -145 -100 -239
-14 -44 -34 -91 -45 -106 -20 -28 -41 -81 -67 -165 -22 -73 -56 -155 -64 -155
-15 0 -57 -91 -84 -181 -15 -52 -31 -101 -36 -109 -32 -54 -69 -135 -89 -195
-12 -38 -31 -90 -41 -115 -22 -52 -61 -180 -79 -260 -7 -30 -16 -62 -20 -70
-42 -84 -100 -269 -100 -319 -1 -38 -43 -206 -57 -222 -44 -55 -94 -257 -108
-441 -6 -64 -11 -81 -34 -107 -33 -37 -44 -70 -71 -226 -23 -132 -32 -172 -78
-350 -27 -108 -62 -283 -77 -390 -3 -22 -12 -78 -20 -125 -15 -91 -38 -326
-56 -580 -6 -85 -17 -213 -25 -285 -36 -326 -49 -598 -49 -1060 0 -628 4 -879
15 -950 5 -33 16 -103 24 -155 9 -52 18 -140 21 -195 4 -55 15 -170 25 -255
10 -85 22 -189 25 -230 4 -41 13 -103 21 -138 8 -35 17 -102 20 -150 6 -99 37
-260 65 -345 10 -31 19 -81 19 -111 0 -30 9 -82 19 -117 11 -35 22 -89 26
-119 12 -105 65 -310 106 -412 11 -26 19 -60 19 -77 0 -72 80 -371 107 -399
20 -21 62 -180 63 -238 0 -38 44 -193 70 -249 10 -22 28 -74 40 -115 42 -151
65 -216 87 -250 13 -19 23 -41 23 -50 1 -8 9 -33 20 -55 11 -22 19 -43 20 -47
0 -3 16 -40 35 -81 19 -41 39 -98 45 -126 5 -28 16 -64 25 -80 8 -17 15 -36
15 -43 0 -8 7 -23 15 -33 47 -62 76 -119 95 -195 12 -47 31 -102 41 -123 10
-22 19 -45 19 -53 0 -8 3 -14 8 -14 13 0 82 -154 82 -184 0 -15 26 -78 57
-141 42 -83 88 -171 93 -175 12 -11 100 -170 100 -181 0 -7 11 -27 25 -45 14
-18 25 -37 25 -43 0 -5 6 -14 14 -18 14 -8 66 -114 66 -134 0 -22 53 -109 75
-123 14 -9 30 -41 45 -88 21 -67 82 -196 98 -208 4 -3 33 -44 65 -92 33 -48
65 -92 73 -99 8 -6 23 -30 34 -53 35 -70 51 -96 59 -96 4 0 25 -33 45 -72 55
-108 61 -116 97 -156 18 -20 51 -71 72 -112 21 -41 56 -97 77 -125 21 -27 49
-69 63 -92 15 -23 39 -56 55 -75 15 -18 35 -45 44 -59 9 -15 40 -52 67 -84 28
-32 51 -63 51 -70 0 -18 83 -115 99 -115 12 0 111 -131 111 -147 0 -7 6 -16
13 -20 7 -4 28 -33 47 -63 44 -71 111 -157 180 -232 30 -33 68 -74 84 -91 17
-18 44 -43 61 -57 17 -14 62 -63 100 -110 38 -47 72 -87 75 -90 3 -3 28 -33
54 -68 48 -63 114 -132 216 -228 80 -74 125 -123 162 -175 18 -26 49 -59 68
-75 19 -16 37 -33 39 -38 5 -12 59 -65 139 -136 34 -30 93 -85 130 -122 37
-38 72 -68 77 -68 6 0 30 -18 54 -40 24 -22 45 -40 46 -40 1 0 41 -37 88 -82
47 -46 100 -94 119 -108 18 -14 69 -59 113 -102 91 -87 198 -174 224 -183 10
-3 27 -18 39 -34 12 -16 46 -43 76 -60 30 -17 84 -61 119 -96 36 -36 69 -65
74 -65 5 0 22 -13 39 -28 17 -16 69 -54 115 -85 46 -31 104 -73 129 -92 25
-19 50 -35 55 -35 6 0 25 -13 42 -28 66 -61 123 -102 191 -138 13 -6 32 -20
42 -30 32 -32 43 -39 105 -70 33 -16 65 -34 70 -40 10 -12 74 -54 125 -83 62
-35 78 -44 120 -71 25 -16 59 -36 75 -45 37 -19 73 -42 116 -77 45 -35 143
-88 163 -88 9 0 24 -9 33 -20 10 -11 23 -20 30 -20 7 0 13 -4 13 -9 0 -5 17
-16 38 -25 57 -26 132 -72 175 -109 38 -32 389 -197 419 -197 5 0 40 -17 76
-37 37 -21 81 -43 97 -49 17 -7 62 -24 100 -38 39 -15 71 -31 73 -36 2 -6 9
-10 16 -10 7 0 21 -9 31 -20 10 -11 25 -20 34 -20 9 0 25 -6 36 -13 11 -8 51
-24 90 -37 38 -12 87 -33 107 -46 21 -13 42 -24 48 -24 6 0 28 -11 48 -25 20
-14 43 -25 52 -25 8 0 44 -16 80 -36 36 -20 107 -48 159 -64 52 -16 97 -33
100 -38 24 -36 241 -112 322 -112 25 0 117 -31 149 -51 65 -39 124 -63 206
-84 52 -14 101 -25 110 -25 9 0 49 -16 89 -36 86 -41 247 -94 288 -94 15 0 58
-9 95 -20 207 -63 322 -94 382 -105 19 -4 50 -13 68 -21 52 -24 179 -55 267
-64 153 -18 300 -42 360 -60 150 -45 461 -90 755 -110 74 -4 164 -15 200 -24
193 -46 189 -45 815 -86 205 -13 1177 -13 1360 0 77 5 239 15 360 21 121 6
272 19 335 29 63 11 138 21 165 24 28 3 66 10 85 16 19 6 91 13 160 16 70 3
145 12 170 21 49 16 129 28 285 43 126 12 272 34 350 53 89 23 214 50 265 57
25 4 108 24 185 44 77 20 149 36 160 36 24 0 193 54 233 74 15 7 53 16 85 20
32 3 74 13 94 21 19 8 45 15 57 15 47 0 257 80 292 111 11 11 31 19 45 19 13
0 58 9 99 20 41 11 89 20 105 20 30 0 187 49 222 69 34 20 124 51 147 51 27 0
119 37 159 64 32 22 153 66 180 66 23 0 101 32 199 81 42 22 82 39 89 39 6 0
27 11 45 25 18 14 39 25 46 25 8 0 29 11 47 24 18 13 56 32 84 41 69 21 177
74 177 86 0 5 4 9 10 9 5 0 26 11 46 25 20 14 46 25 58 25 12 0 31 6 42 14 11
8 37 18 59 22 34 6 133 48 175 73 8 5 49 26 90 45 41 20 82 40 90 45 8 5 42
19 75 31 78 28 131 56 180 95 22 17 70 46 107 65 36 18 83 49 105 69 38 36 49
43 178 111 151 80 230 123 235 129 3 3 14 12 25 19 53 33 81 52 90 59 6 4 21
13 35 19 14 6 45 27 70 45 25 19 59 41 75 49 17 8 51 36 78 62 26 26 64 55 85
64 108 48 128 61 174 105 28 27 56 49 63 49 7 0 18 6 24 14 7 8 62 48 124 90
62 41 129 90 150 108 21 18 49 42 63 53 14 11 49 41 77 68 28 26 56 47 62 47
7 0 40 27 73 60 34 33 64 60 67 60 11 0 105 72 105 81 0 5 7 9 15 9 8 0 19 9
25 20 6 11 14 20 19 20 4 0 37 26 72 59 185 169 303 273 321 279 4 2 8 10 8
18 0 8 3 14 8 14 13 0 111 77 167 132 30 29 83 80 119 113 36 33 80 75 98 94
159 166 204 211 213 211 13 0 75 64 75 78 0 6 19 24 43 40 23 17 53 49 67 72
14 23 33 49 43 58 9 9 17 22 17 29 0 6 9 14 19 18 11 3 26 16 34 28 8 12 41
49 73 82 85 86 174 190 174 203 0 6 9 16 20 22 11 6 20 16 20 23 0 7 9 18 20
25 11 7 20 19 20 27 0 8 4 15 9 15 11 0 121 135 121 149 0 6 6 11 13 11 6 0
27 19 45 43 72 94 139 184 162 219 14 21 28 38 33 38 4 0 7 6 7 13 0 6 18 28
40 47 22 19 40 41 40 48 0 6 4 12 9 12 5 0 21 20 35 45 15 25 30 45 34 45 7 0
18 18 47 80 17 34 83 122 97 128 9 4 78 100 78 110 1 4 30 41 65 82 36 41 65
79 65 83 0 5 8 21 19 35 20 29 72 121 104 186 10 21 23 44 28 50 48 57 59 72
59 81 0 21 43 91 77 127 38 39 45 50 93 144 18 34 43 73 56 87 13 14 24 29 24
33 0 21 87 184 119 223 35 42 131 201 131 217 0 8 16 42 108 234 33 69 66 145
72 170 7 25 31 81 54 125 23 44 59 121 80 172 21 51 47 100 57 109 11 9 19 21
19 26 0 5 8 21 17 35 10 15 29 61 43 102 14 42 34 92 45 111 23 42 36 74 81
209 19 57 41 116 48 130 29 53 57 123 62 154 13 69 48 176 81 246 19 41 41 95
48 120 19 66 46 141 65 181 9 19 27 78 39 130 32 137 51 202 67 227 54 86 58
97 62 201 7 173 -30 281 -115 333 -27 17 -48 21 -106 19 -40 -1 -508 -3 -1042
-4 l-970 -1 -105 -28 c-111 -29 -170 -42 -365 -77 -66 -12 -151 -36 -190 -52
-38 -17 -87 -37 -109 -44 -42 -16 -107 -58 -146 -96 -14 -13 -38 -29 -53 -36
-39 -17 -160 -97 -176 -116 -7 -9 -20 -16 -29 -16 -16 0 -222 -206 -222 -222
0 -5 -16 -26 -36 -46 -38 -39 -94 -117 -94 -131 0 -21 -56 -128 -78 -151 -41
-42 -84 -115 -199 -339 -35 -69 -69 -132 -77 -140 -20 -23 -53 -102 -70 -170
-11 -45 -25 -69 -58 -103 -24 -24 -50 -57 -58 -73 -8 -17 -43 -73 -77 -126
-35 -53 -63 -103 -63 -112 0 -8 -20 -40 -45 -70 -45 -56 -87 -119 -126 -192
-12 -22 -31 -53 -44 -70 -12 -16 -30 -46 -40 -65 -10 -19 -29 -43 -42 -54 -37
-28 -58 -53 -73 -84 -18 -34 -50 -72 -63 -72 -13 0 -56 -46 -88 -95 -49 -74
-262 -275 -291 -275 -43 0 -70 93 -67 237 3 144 -21 718 -31 758 -6 22 -15 94
-19 160 -9 117 -22 188 -62 330 -10 39 -19 79 -19 90 0 18 -16 80 -72 270 -40
134 -61 200 -74 235 -7 19 -28 80 -45 135 -42 135 -66 199 -99 270 -16 33 -38
86 -50 118 -12 31 -28 63 -36 71 -8 8 -14 18 -14 22 0 5 -11 17 -25 29 -14 11
-36 49 -49 85 -38 103 -53 133 -79 162 -14 15 -48 69 -76 121 -28 52 -64 111
-81 132 -16 21 -30 43 -30 50 0 17 -89 185 -107 203 -7 7 -13 19 -13 25 0 14
-86 107 -98 107 -5 0 -26 34 -46 76 -20 42 -43 82 -51 91 -8 8 -15 20 -15 26
0 7 -11 26 -25 42 -14 17 -33 41 -43 54 -9 13 -35 43 -58 65 -22 23 -60 67
-83 100 -45 61 -141 160 -181 186 -28 19 -41 32 -60 61 -19 29 -79 89 -89 89
-5 0 -25 26 -46 58 -21 31 -45 62 -54 69 -9 6 -42 37 -75 67 -32 31 -63 56
-68 56 -5 0 -38 29 -73 65 -34 36 -68 65 -74 65 -6 0 -11 6 -11 13 0 7 -8 20
-17 29 -10 9 -30 30 -45 47 -15 17 -32 31 -37 31 -5 0 -35 29 -67 65 -32 36
-63 65 -68 65 -5 0 -28 19 -51 43 -66 65 -175 160 -209 182 -17 10 -58 31 -90
46 -41 19 -66 38 -80 61 -29 48 -62 88 -72 88 -5 0 -27 17 -49 36 -22 20 -67
50 -100 66 -51 26 -86 53 -176 134 -9 8 -21 14 -27 14 -5 0 -12 4 -14 8 -4 11
-94 72 -106 72 -5 0 -15 6 -21 14 -7 8 -23 17 -38 21 -14 3 -42 23 -63 45 -20
21 -52 46 -71 54 -53 25 -99 54 -161 102 -31 25 -72 51 -90 58 -18 8 -50 30
-70 50 -20 20 -40 36 -43 36 -3 0 -15 9 -26 19 -12 11 -50 33 -86 51 -35 18
-67 38 -70 46 -3 8 -9 14 -15 14 -5 0 -36 27 -69 60 -32 33 -64 60 -69 60 -5
0 -24 9 -42 20 -18 11 -40 20 -48 20 -20 0 -110 48 -135 71 -42 40 -68 58 -93
64 -15 3 -45 19 -67 35 -92 67 -186 130 -194 130 -4 0 -16 9 -26 20 -10 11
-23 20 -30 20 -7 0 -20 9 -30 20 -10 11 -23 20 -29 20 -6 0 -34 15 -61 33
-117 76 -216 137 -224 137 -5 0 -14 6 -20 14 -10 12 -36 26 -141 75 -65 31
-100 53 -131 81 -19 16 -63 44 -99 61 -36 17 -84 47 -107 65 -23 19 -44 34
-48 34 -3 0 -21 11 -39 25 -18 14 -36 25 -40 25 -4 0 -24 13 -44 29 -20 16
-69 45 -109 66 -39 20 -77 43 -83 51 -7 8 -17 14 -23 14 -5 0 -24 10 -41 23
-42 30 -111 67 -126 67 -7 0 -36 17 -64 38 -28 21 -81 53 -118 71 -38 18 -88
49 -112 70 -41 36 -182 111 -207 111 -6 0 -32 17 -58 39 -25 21 -57 44 -70 51
-14 7 -30 17 -35 21 -53 42 -83 61 -161 99 -49 24 -110 59 -134 77 -24 19 -60
42 -80 51 -57 29 -85 46 -94 59 -9 15 -150 103 -164 103 -5 0 -18 10 -29 21
-10 12 -28 25 -39 30 -11 5 -40 23 -65 39 -25 16 -54 34 -65 39 -11 6 -38 24
-60 41 -22 16 -50 35 -62 41 -13 7 -23 16 -23 20 0 5 -13 15 -29 24 -16 8 -35
21 -43 28 -7 7 -40 26 -73 42 -33 16 -66 35 -73 42 -7 7 -20 13 -27 13 -8 0
-34 19 -60 42 -25 23 -70 54 -100 68 -30 14 -71 41 -92 58 -100 85 -223 178
-272 207 -9 6 -35 24 -56 41 -22 17 -67 45 -100 64 -33 18 -89 61 -125 94 -63
60 -112 103 -202 176 -23 19 -65 55 -93 80 -67 61 -159 129 -215 157 -42 23
-100 74 -208 187 -126 131 -282 283 -327 317 -51 39 -155 148 -240 254 -27 33
-54 64 -62 68 -7 4 -13 13 -13 20 0 11 -10 24 -95 122 -19 22 -35 43 -35 47 0
5 -14 23 -31 41 -58 59 -128 142 -153 180 -14 21 -37 50 -51 65 -14 15 -25 31
-25 37 0 5 -25 38 -56 73 -31 34 -72 92 -91 130 -20 37 -50 90 -67 117 -17 28
-42 75 -56 105 -13 30 -34 69 -47 87 -13 17 -23 38 -23 47 0 8 -4 17 -9 20 -5
4 -22 39 -37 79 -15 39 -37 95 -49 122 -49 110 -64 152 -68 195 -2 25 -14 79
-26 120 -112 379 -104 336 -98 580 6 211 29 402 56 460 6 14 18 57 25 95 8 39
20 88 26 110 6 22 15 58 20 80 4 22 25 73 45 115 61 122 75 154 75 170 0 8 17
42 38 75 20 33 59 105 86 160 27 55 57 112 68 127 10 14 18 32 18 39 0 6 11
27 25 45 14 18 25 37 25 42 0 4 25 37 55 72 30 35 57 73 61 84 8 26 54 76 69
76 12 0 195 176 195 188 1 10 45 58 57 63 6 2 34 32 63 67 28 34 57 62 63 62
7 0 33 20 59 45 26 24 66 56 90 71 24 15 54 39 68 53 14 14 37 34 50 43 14 10
44 31 68 48 23 16 45 30 48 30 3 0 26 16 50 34 25 19 64 44 87 55 23 11 70 43
104 71 34 27 66 50 71 50 5 0 19 11 32 25 13 14 28 25 33 25 5 0 45 18 88 40
44 22 87 40 97 40 9 1 35 9 57 20 22 11 47 19 56 20 8 0 19 4 25 9 29 29 180
94 254 111 32 7 85 24 215 70 88 31 178 58 260 78 44 11 98 24 120 30 22 5 54
15 70 20 30 11 101 19 459 48 206 17 431 15 726 -5 74 -6 293 -15 485 -21 299
-9 357 -13 400 -29 115 -43 354 -96 475 -106 47 -3 105 -13 130 -20 73 -22
169 -45 255 -61 44 -8 100 -20 125 -28 113 -34 203 -57 295 -76 182 -38 259
-61 335 -100 75 -38 126 -54 292 -90 47 -10 104 -30 126 -44 41 -26 121 -58
202 -80 25 -7 79 -29 120 -48 112 -53 168 -78 177 -78 4 -1 26 -9 48 -20 22
-11 44 -19 48 -20 5 0 23 -11 41 -25 18 -14 38 -25 44 -25 6 0 41 -16 79 -37
158 -84 237 -123 250 -123 19 0 174 -55 215 -76 18 -9 54 -34 79 -55 26 -22
52 -39 58 -39 6 0 16 -9 23 -20 7 -11 17 -20 22 -20 5 0 17 -6 25 -14 16 -14
45 -31 173 -99 78 -42 106 -57 131 -69 34 -16 60 -33 87 -58 15 -14 63 -43
106 -65 43 -22 96 -57 117 -77 21 -21 44 -38 52 -38 8 0 15 -4 15 -9 0 -5 15
-14 33 -22 17 -7 46 -24 63 -38 17 -14 57 -45 90 -68 32 -23 70 -53 84 -65 35
-32 128 -98 139 -98 5 0 33 -19 64 -42 30 -24 65 -50 77 -58 12 -8 45 -37 74
-65 29 -27 86 -78 127 -112 40 -34 82 -69 92 -78 9 -8 21 -15 27 -15 5 0 13
-6 17 -12 4 -7 62 -62 128 -123 147 -135 138 -127 165 -145 12 -8 43 -34 68
-57 26 -23 58 -47 73 -53 14 -6 33 -25 42 -43 27 -52 36 -63 162 -195 158
-167 295 -302 306 -302 5 0 18 -15 29 -32 11 -18 42 -58 70 -88 57 -63 150
-187 150 -200 0 -5 8 -11 18 -15 9 -3 31 -25 49 -48 17 -23 57 -71 87 -106 31
-36 56 -68 56 -72 0 -5 22 -30 49 -57 27 -27 59 -67 70 -88 12 -22 46 -70 76
-109 30 -38 55 -77 55 -85 0 -8 9 -26 20 -40 11 -14 20 -30 20 -37 0 -7 9 -17
19 -22 20 -11 111 -143 111 -161 0 -5 3 -10 8 -10 4 0 13 -15 21 -32 8 -18 22
-42 31 -53 10 -11 26 -35 36 -54 34 -61 41 -72 62 -94 12 -12 31 -45 43 -72
25 -56 50 -96 91 -140 15 -17 43 -62 62 -101 19 -39 46 -84 61 -100 24 -26 56
-88 117 -224 11 -25 26 -56 34 -70 24 -41 64 -132 71 -159 3 -14 14 -38 24
-53 11 -14 19 -30 19 -34 0 -4 6 -15 14 -23 7 -9 28 -50 46 -91 18 -41 47 -93
65 -115 18 -22 38 -51 45 -65 7 -14 19 -29 26 -33 8 -4 14 -12 14 -16 0 -11
55 -71 65 -71 12 0 48 -42 85 -101 19 -29 45 -63 58 -74 14 -11 43 -38 66 -61
22 -22 59 -51 81 -65 22 -13 47 -28 56 -34 53 -33 108 -59 167 -80 37 -13 83
-29 102 -35 19 -7 50 -19 69 -27 18 -8 84 -25 145 -38 166 -36 468 -47 1336
-51 415 -1 887 -5 1048 -8 285 -6 294 -6 345 16 87 36 108 65 120 161 11 92
-7 275 -36 357 -9 25 -26 79 -38 120 -12 41 -31 94 -41 117 -10 24 -18 48 -18
53 0 6 -9 28 -19 48 -11 20 -32 82 -47 137 -28 104 -56 189 -80 240 -8 17 -21
53 -29 80 -8 28 -22 79 -33 115 -10 36 -35 99 -55 140 -39 80 -60 135 -82 213
-7 27 -38 99 -68 160 -101 209 -128 270 -167 386 -32 95 -49 133 -85 186 -23
33 -33 54 -66 133 -12 28 -25 52 -30 52 -5 0 -9 6 -9 13 0 7 -13 28 -29 46
-16 18 -34 47 -41 64 -6 18 -20 55 -30 82 -34 95 -43 117 -54 129 -13 14 -25
35 -79 135 -22 42 -43 78 -47 81 -3 3 -10 12 -13 20 -4 8 -16 29 -26 45 -11
17 -20 37 -20 46 -1 8 -23 40 -49 70 -48 54 -72 97 -72 126 0 33 -60 131 -114
187 -31 32 -56 62 -56 67 0 18 -50 115 -69 136 -12 12 -21 26 -21 31 0 15 -49
99 -65 112 -8 7 -15 16 -15 21 0 5 -7 17 -16 27 -9 9 -37 51 -63 92 -27 41
-58 84 -70 94 -11 11 -21 25 -21 33 0 23 -49 89 -90 122 -22 18 -40 36 -40 41
0 5 -18 36 -40 69 -22 33 -40 65 -40 70 0 6 -6 14 -14 18 -17 9 -61 68 -75
101 -6 12 -15 22 -20 22 -5 0 -12 9 -16 19 -3 11 -15 23 -26 26 -10 4 -19 11
-19 16 0 15 -48 78 -92 121 -21 21 -38 42 -38 46 0 5 -9 17 -20 27 -11 10 -20
24 -20 32 0 7 -6 13 -13 13 -17 0 -73 59 -81 85 -3 11 -22 38 -41 59 -19 22
-35 43 -35 47 0 4 -11 10 -25 13 -14 4 -25 12 -25 20 0 7 -7 19 -15 26 -8 6
-26 36 -39 66 -29 63 -74 123 -136 181 -60 57 -53 50 -113 116 -30 31 -59 57
-65 57 -6 0 -14 9 -17 20 -3 11 -11 20 -16 20 -5 0 -13 8 -16 17 -8 20 -54 62
-68 63 -6 0 -30 26 -55 58 -46 58 -206 229 -293 311 -27 25 -129 125 -227 221
-228 223 -300 291 -329 310 -13 8 -40 29 -59 47 -20 17 -52 40 -72 49 -20 10
-51 37 -70 61 -40 50 -149 142 -197 167 -18 9 -33 21 -33 26 0 6 -6 10 -13 10
-7 0 -42 28 -78 63 -73 72 -120 107 -142 107 -9 0 -19 9 -22 20 -3 11 -13 20
-21 20 -8 0 -17 7 -21 16 -3 8 -23 22 -44 31 -22 8 -39 19 -39 24 0 5 -4 9
-10 9 -5 0 -28 18 -50 39 -23 22 -64 54 -93 72 -29 18 -56 39 -60 46 -4 7 -12
13 -17 13 -6 0 -36 20 -69 45 -32 25 -61 45 -64 45 -2 0 -23 17 -45 38 -22 20
-65 50 -97 66 -31 15 -75 46 -98 67 -23 21 -71 53 -107 71 -36 17 -71 38 -78
45 -34 34 -128 94 -192 123 -8 4 -35 16 -60 28 -25 12 -58 24 -75 28 -39 9
-70 29 -120 74 -39 36 -147 90 -179 90 -21 0 -81 38 -129 83 -37 35 -114 77
-141 77 -8 0 -16 6 -19 14 -3 7 -34 29 -69 48 -35 20 -84 47 -110 61 -25 14
-63 36 -85 50 -21 13 -63 35 -93 48 -30 13 -97 45 -149 72 -52 26 -99 47 -103
47 -10 0 -93 36 -128 55 -81 44 -268 115 -305 115 -8 0 -15 4 -15 9 0 7 -57
37 -251 131 -37 19 -151 62 -196 75 -78 22 -167 55 -177 65 -6 6 -17 10 -27
10 -9 0 -38 8 -65 19 -61 23 -169 57 -209 66 -16 3 -48 14 -70 24 -22 9 -71
27 -110 38 -148 44 -211 62 -245 68 -19 4 -66 22 -105 40 -86 40 -157 61 -255
75 -41 7 -143 29 -226 51 -84 21 -164 39 -180 39 -15 0 -59 9 -98 21 -39 11
-100 29 -136 40 -36 10 -94 28 -130 39 -35 11 -105 25 -155 30 -49 6 -128 17
-175 25 -47 8 -99 15 -115 15 -30 0 -178 35 -267 64 -45 14 -318 35 -733 56
-99 5 -282 18 -407 29 -181 16 -337 21 -765 25 -296 2 -599 0 -673 -4z m-6960
-7087 c25 -36 25 -39 23 -222 -2 -102 4 -269 12 -371 8 -102 15 -230 15 -285
0 -57 7 -126 16 -160 8 -33 19 -82 24 -110 5 -27 15 -70 23 -95 8 -25 27 -106
41 -180 46 -229 114 -463 167 -574 11 -22 33 -97 49 -166 33 -138 56 -199 101
-263 26 -38 44 -78 80 -177 18 -53 51 -122 71 -152 10 -14 18 -31 18 -37 0 -6
7 -19 15 -28 43 -53 83 -121 129 -222 6 -14 28 -42 48 -63 21 -22 45 -56 54
-76 35 -79 69 -138 96 -167 16 -16 36 -43 45 -60 9 -16 22 -35 29 -43 8 -7 14
-20 14 -28 0 -8 4 -14 9 -14 8 0 30 -30 67 -92 6 -10 21 -29 33 -42 11 -12 21
-26 21 -30 0 -4 19 -36 42 -69 23 -34 51 -77 63 -95 29 -43 108 -135 165 -192
25 -25 65 -72 89 -105 49 -65 141 -165 153 -165 8 0 38 -30 163 -167 50 -54
110 -116 134 -138 25 -22 77 -74 115 -115 121 -127 197 -200 227 -216 16 -8
29 -19 29 -24 0 -6 6 -10 14 -10 8 0 21 -8 28 -18 29 -42 219 -192 242 -192 8
0 29 -19 46 -43 17 -24 50 -56 73 -70 24 -15 72 -54 107 -87 36 -33 69 -60 74
-60 5 0 41 -26 80 -58 39 -32 93 -69 119 -82 27 -14 53 -32 58 -41 4 -9 34
-34 66 -55 32 -20 76 -53 98 -71 53 -45 55 -47 90 -65 68 -35 82 -44 112 -76
32 -34 116 -92 133 -92 5 0 14 -9 20 -20 6 -11 17 -20 25 -20 8 0 15 -4 15 -9
0 -8 84 -62 143 -91 15 -7 27 -17 27 -22 0 -4 7 -8 15 -8 9 0 24 -8 35 -17 10
-10 41 -29 67 -43 26 -14 53 -30 58 -36 12 -14 90 -64 129 -84 16 -8 42 -27
59 -42 16 -16 33 -28 37 -28 5 0 15 -8 22 -17 16 -21 39 -36 93 -62 22 -11 51
-30 65 -43 24 -23 117 -88 125 -88 3 0 31 -17 62 -37 32 -21 67 -43 78 -49 11
-6 29 -16 40 -22 11 -6 36 -18 55 -27 19 -8 67 -33 105 -55 39 -21 81 -45 95
-51 14 -7 45 -29 70 -49 46 -38 108 -72 196 -110 28 -12 68 -39 90 -60 21 -22
46 -39 55 -40 9 0 22 -6 28 -14 7 -8 26 -22 44 -31 18 -9 52 -29 77 -45 25
-15 73 -42 107 -59 34 -17 65 -36 68 -41 4 -6 23 -19 43 -30 55 -30 73 -42
107 -72 37 -34 127 -78 175 -86 19 -3 50 -14 68 -24 18 -10 37 -18 43 -18 6 0
29 -19 51 -41 23 -23 67 -53 98 -68 31 -15 77 -43 101 -63 93 -75 176 -128
283 -180 33 -16 78 -44 100 -63 44 -35 156 -95 179 -95 7 0 25 -13 40 -28 15
-16 61 -46 103 -68 41 -21 91 -53 109 -70 41 -37 111 -84 125 -84 6 0 35 -18
65 -40 30 -22 61 -40 70 -40 8 0 15 -4 15 -9 0 -5 15 -16 33 -25 17 -9 55 -31
82 -48 64 -40 112 -67 150 -87 17 -8 35 -20 42 -25 15 -14 60 -50 99 -81 18
-14 36 -25 42 -25 16 0 102 -51 102 -61 0 -5 8 -13 18 -16 27 -11 83 -50 127
-88 45 -39 161 -125 170 -125 4 0 23 -13 43 -29 20 -16 64 -44 97 -61 33 -18
71 -43 86 -56 14 -13 32 -24 40 -24 20 0 112 -67 165 -120 22 -22 44 -40 48
-40 4 0 19 -11 34 -25 15 -14 35 -25 44 -25 10 0 18 -6 18 -13 0 -8 29 -35 64
-61 35 -25 85 -67 112 -91 27 -25 53 -45 57 -45 5 0 28 -20 51 -44 23 -24 69
-60 101 -80 32 -21 86 -67 121 -102 35 -35 69 -64 76 -64 19 0 228 -210 228
-229 0 -5 11 -16 23 -24 13 -8 41 -35 63 -58 71 -77 237 -239 246 -239 4 0 8
-5 8 -12 0 -6 7 -18 15 -27 58 -62 96 -104 141 -157 29 -34 74 -83 101 -110
28 -28 58 -63 69 -79 10 -17 37 -53 59 -81 22 -28 47 -61 55 -74 19 -29 31
-41 64 -65 25 -18 36 -35 84 -121 7 -13 17 -24 22 -24 5 0 36 -56 70 -125 33
-69 64 -125 68 -125 7 0 17 -17 48 -80 9 -19 25 -45 35 -59 10 -13 19 -30 19
-39 0 -8 15 -40 34 -71 18 -31 37 -67 41 -81 3 -14 15 -44 26 -67 10 -24 19
-48 19 -53 0 -6 9 -28 20 -50 25 -48 70 -186 70 -212 0 -11 8 -50 19 -86 58
-207 77 -392 75 -727 -1 -166 -10 -224 -59 -400 -14 -49 -30 -108 -36 -130 -5
-21 -16 -53 -24 -70 -22 -47 -52 -146 -60 -195 -6 -37 -55 -142 -74 -160 -6
-5 -26 -39 -39 -67 -37 -77 -62 -133 -72 -158 -6 -16 -30 -44 -53 -60 -23 -17
-48 -44 -56 -60 -12 -27 -52 -92 -74 -120 -4 -5 -20 -29 -35 -52 -15 -23 -51
-68 -81 -100 -29 -32 -67 -78 -84 -103 -16 -25 -39 -54 -51 -66 -12 -11 -43
-46 -70 -77 -27 -31 -54 -57 -60 -57 -16 0 -91 -53 -130 -91 -19 -19 -55 -52
-80 -74 -26 -22 -60 -52 -76 -67 -16 -15 -46 -38 -67 -52 -21 -13 -54 -39 -73
-57 -33 -32 -45 -40 -105 -73 -14 -7 -38 -27 -54 -43 -16 -17 -42 -37 -57 -45
-16 -8 -38 -19 -49 -25 -11 -6 -49 -25 -85 -43 -36 -18 -81 -45 -100 -60 -45
-36 -151 -80 -190 -80 -34 0 -151 -52 -171 -76 -6 -8 -18 -14 -26 -14 -9 0
-32 -8 -52 -19 -95 -49 -227 -101 -259 -101 -9 -1 -35 -9 -57 -20 -22 -11 -46
-19 -54 -20 -8 0 -33 -11 -56 -24 -52 -31 -141 -52 -278 -66 -103 -10 -195
-33 -254 -63 -126 -64 -936 -71 -1323 -12 -112 17 -161 22 -295 30 -146 9
-567 73 -695 106 -41 11 -115 24 -165 30 -49 5 -112 16 -140 24 -27 8 -111 28
-185 45 -171 38 -210 48 -288 71 -34 10 -70 19 -79 19 -9 0 -66 20 -127 44
-61 24 -140 48 -176 55 -166 28 -239 51 -300 93 -14 9 -56 26 -95 37 -38 11
-117 34 -174 51 -58 17 -112 36 -120 43 -27 22 -213 97 -242 97 -9 0 -24 7
-33 15 -20 20 -209 115 -229 115 -9 0 -22 7 -30 15 -8 8 -32 21 -53 29 -22 8
-66 24 -99 36 -33 12 -64 26 -69 31 -6 5 -16 9 -24 9 -8 0 -31 14 -51 30 -53
45 -52 44 -113 73 -31 15 -64 27 -73 27 -9 0 -33 13 -53 29 -20 16 -53 38 -72
49 -75 42 -115 66 -121 75 -9 13 -166 97 -182 97 -3 0 -34 19 -69 42 -75 51
-95 63 -128 80 -14 7 -45 28 -70 48 -25 20 -65 48 -90 61 -25 13 -49 29 -55
35 -15 17 -143 114 -150 114 -9 0 -73 50 -120 92 -22 21 -44 38 -48 38 -4 0
-18 9 -30 21 -36 34 -127 93 -199 129 -37 18 -83 47 -102 64 -38 35 -60 51
-93 68 -13 6 -23 16 -23 23 0 16 -91 105 -107 105 -7 0 -13 4 -13 9 0 16 -42
56 -88 85 -24 15 -64 47 -90 71 -26 25 -50 45 -54 45 -5 0 -38 30 -76 68 -100
99 -219 206 -267 240 -6 4 -28 31 -50 60 -21 28 -42 52 -46 52 -4 0 -10 9 -14
20 -5 17 -67 80 -212 218 -24 22 -43 45 -43 50 0 6 -25 32 -56 58 -30 26 -64
64 -75 83 -25 46 -42 75 -50 81 -3 3 -18 24 -32 47 -15 23 -73 89 -131 147
-58 58 -121 133 -142 166 -20 33 -40 60 -45 60 -4 0 -11 9 -14 20 -3 11 -12
20 -20 20 -20 0 -93 90 -101 123 -3 15 -16 40 -28 55 -12 15 -44 63 -71 107
-27 44 -60 91 -73 105 -22 22 -92 125 -92 134 0 2 -16 21 -35 42 -19 21 -37
47 -41 58 -9 33 -38 86 -46 86 -5 0 -8 5 -8 10 0 11 -34 63 -58 88 -7 8 -26
41 -43 74 -16 32 -38 66 -49 75 -11 9 -29 42 -40 72 -12 31 -31 81 -42 111
-11 30 -40 85 -64 121 -24 36 -44 69 -44 73 0 9 -91 169 -100 176 -3 3 -17 27
-30 54 -13 27 -45 81 -70 120 -26 39 -56 100 -68 136 -12 36 -33 101 -47 145
-15 44 -35 95 -46 113 -10 18 -19 38 -19 44 0 6 -17 48 -39 92 -21 45 -43 99
-49 121 -18 73 -54 163 -122 310 -21 44 -41 103 -45 130 -19 134 -64 269 -108
324 -17 20 -46 134 -62 242 -18 120 -38 199 -64 247 -19 37 -42 136 -60 262
-6 36 -15 92 -21 125 -5 33 -15 103 -20 155 -6 52 -17 117 -25 145 -32 111
-57 248 -71 395 -8 85 -19 191 -24 235 -6 44 -10 138 -10 210 0 71 -7 243 -16
380 -20 318 -23 658 -6 850 6 83 16 233 21 335 9 176 15 231 61 550 11 74 22
162 26 195 3 33 14 92 24 130 10 39 27 140 39 225 37 264 62 402 86 470 13 36
32 117 44 180 11 63 30 163 41 222 11 59 20 117 21 130 0 13 13 46 29 73 53
90 56 99 80 200 12 55 40 136 61 181 22 44 39 87 39 95 0 8 4 19 9 25 9 9 25
51 116 311 29 83 60 164 68 180 43 77 107 270 107 320 0 7 16 34 36 60 36 47
78 132 91 182 3 13 23 47 45 75 21 28 38 57 38 64 0 8 9 25 20 39 11 14 20 31
20 38 0 19 30 75 41 75 5 0 9 6 9 13 0 8 9 22 20 32 11 10 20 22 20 27 0 11
90 171 100 178 4 3 24 40 45 82 20 42 43 82 51 89 8 6 14 18 14 25 0 8 4 14 8
14 5 0 14 11 20 24 14 31 64 99 156 212 14 18 26 36 26 42 0 6 21 33 48 60 68
72 136 169 179 254 38 76 69 118 87 118 6 0 37 24 70 54 32 30 70 60 85 67 14
7 34 21 44 31 27 27 49 22 77 -19z"/>
</g>
      </svg>
    </Box>
  );

  if (disabledLink) {
    return <>{logo}</>;
  }

  return <RouterLink to="">{logo}</RouterLink>;
}
